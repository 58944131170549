/*! Page Header and Navigation
---------------------------------------------- */
.page-header {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  transition: transform $t2 ease 0s;
  z-index: 10;
}

.top-nav {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F0F8E9;
  color: #007934;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 999;

  .left-search {
    position: relative;
    margin-right: auto;

    form {
      position: relative;
    }

    input {
      width: 340px;
      border-radius: 50px;
      border: 1px solid rgba(0, 121, 52, 0.30);
      background: #fff;
      padding: 10px 20px;
      font-family: $fontText;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      color: #007934;

      @media (max-width: 767px) {
        width: auto;
        min-width: 250px;
      }

      &::-webkit-input-placeholder {
        color: #007934;
      }
    }

    button {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      z-index: 2;
      background: none;
      border: none;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  .right-links {
    
  }

  .link {
    font-family: $fontText;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-left: 30px;
    color: #007934;
    transition: opacity 0.3s ease 0s;

    @media (max-width: 1205px) {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }

    &.alternate {
      color: #f15f48;
    }
  }

  .link.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid rgba(0, 121, 52, 0.30);
    text-align: center;
    line-height: 40px;
    padding-left: 0;
    margin-left: 30px;
  }
}

.main-nav {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;

  @media (max-width: 1205px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  @media (max-width: 599px) {
    align-items: center;
  }

  .alternate-logo {
    display: none;
  }

  .slogan {
    display: inline-block;
    width: 140px;
    height: auto;
    margin-left: 2.5vw;

    @media (max-width: 1205px) {
      display: none;
    }
  }

  .logo {
    min-width: 155px;
    margin-right: auto;

    svg {
      width: 90%;
      max-width: 155px;
    }

    @media (max-width: 1205px) {
      width: auto;
      min-width: 125px;
      margin-right: 0;

      svg {
        max-width: 125px;
      }
    }

    @media (max-width: 375px) {
      width: auto;
      min-width: 105px;

      svg {
        max-width: 105px;
      }
    }
  }

  .nav-links {
    padding: 0 0;
    padding-left: 25px;
    justify-content: flex-start;

    @media (max-width: 1205px) {
      width: auto;
    }

    ul, li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .with-subnav {

      @media (max-width: 1205px) {
        display: none;
      }

      a {
        display: flex;
        align-items: center;
        line-height: 1;

        span {
          line-height: 1;
        }

        svg {
          position: relative;
          top: -1px;
          margin-left: 8px;
        }

        &::before {
          display: none;
        }
      }
    }

    a {
      position: relative;
      color: #fff;
      padding: 7px;
      margin: 0 12px;
      text-transform: uppercase;

      font-family: $fontTitle;
      font-size: 18px;
      font-weight: blod;

      @media (max-width: 1205px) {
        display: none;
      }

      @media (max-width: 1300px) {
        font-size: 15px;
        margin: 0 6px;
        padding: 7px 6px;
      }

      span {
        position: relative;
        z-index: 2;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 125%);
        background: #fff;
        z-index: 1;
        height: 4px;
        border-radius: 4px;
        width: 0%;

        transition: width $t2 $bounce 0s;
      }

      @media (hover: hover) {
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }

      &.is-active {

        span {
        }

        svg {
          path {
            fill: #f15f48;
          }
        }

        &:before {
          width: 100%;
        }
      }
    }
  }

  .nav-cta {
    min-width: 155px;
    margin-left: auto;

    @media (max-width: 1205px) {
      width: auto;
      min-width: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0;
    }

    .site-btn {
      margin-left: 15px;
    }
  }
}

.white-nav .page-header {
  background: #fff;
}

.white-nav .main-nav {
  padding-top: 12px;
  padding-bottom: 12px;

  @media (max-width: 1205px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .logo {
    min-width: 155px;

    svg {
      width: 90%;
      max-width: 155px;
    }

    @media (max-width: 1205px) {
      width: auto;
      min-width: 125px;

      svg {
        max-width: 125px;
      }
    }

    @media (max-width: 375px) {
      width: auto;
      min-width: 105px;

      svg {
        max-width: 105px;
      }
    }
  }

  .nav-links a {
    color: $colorAccent1;

    svg {
      path {
        fill: $colorAccent1;
      }
    }

    &:before {
      background: $colorAccent1;
    }

    &.is-active {
      span {
        color: $colorAccent2;
      }

      svg {
        path {
          fill: $colorAccent2;
        }
      }
    }
  }

  .lang {
    color: $colorAccent1;
  }

  .site-btn {
    background: $colorAccent1;
    border-color: $colorAccent1;

    @media (hover: hover) {
      &:hover {
        background: #fff;
        color: $colorAccent1;
      }

      &.is-outlined {
        background: transparent;
        color: #fff;
        border-color: $colorAccent1;

        @media (hover: hover) {
          &:hover {
            background: $colorAccent2;
            border-color: $colorAccent2;
            color: #fff;
          }
        }
      }
    }
  }

  .site-btn.is-outlined {
    color: $colorAccent1;
  }
}

.home-nav .main-nav {
  .site-btn {
    background: #fff;
    border-color: #fff;
    color: $colorAccent2;

    @media (hover: hover) {
      &:hover {
        background: $colorAccent1;
        border-color: $colorAccent1;
        color: #fff;
      }
    }
  }

  .site-btn.is-outlined {
    background: transparent;
    color: #fff;
    border-color: #fff;

    @media (hover: hover) {
      &:hover {
        background: $colorAccent2;
        border-color: $colorAccent2;
        color: #fff;
      }
    }
  }
}


html body.hide-nav {
  .page-header {
    position: fixed;
    top: 0 !important; 
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
    box-shadow: 0 0.69444vw 1.38889vw rgb(89 102 132 / 12%);

    .slogan {
      display: none;
    }

    .default-logo {
      display: none;
    }

    .alternate-logo {
      display: block;
    }

    .main-nav {
      padding-top: 20px;
      padding-bottom: 20px;

      .logo {
        min-width: 115px;

        svg {
          width: 90%;
          max-width: 115px;
        }

        @media (max-width: 375px) {
          width: auto;
          min-width: 95px;

          svg {
            max-width: 95px;
          }
        }
      }

      .nav-links a {
        color: $colorAccent1;

        svg {
          path {
            fill: $colorAccent1;
          }
        }

        &:before {
          background: $colorAccent1;
        }
      }

      .lang {
        color: $colorAccent1;
      }

      .mobile-menu-trigger {
        fill: $colorAccent1;
      }

      .site-btn {
        background: $colorAccent1;
        border-color: $colorAccent1;
        color: #fff;

        @media (hover: hover) {
          &:hover {
            background: #fff;
            color: $colorAccent1;
          }
        }
      }

      .site-btn.is-outlined {
        color: $colorAccent1;
        border-color: $colorAccent1;
        background: transparent;

        @media (hover: hover) {
          &:hover {
            border-color: $colorAccent2;
            color: $colorAccent2;
          }
        }
      }
    }
  }
}

.page-header.force-alt {
    position: fixed;
    top: 0;
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
    box-shadow: 0 0.69444vw 1.38889vw rgb(89 102 132 / 12%);

    .slogan {
      display: none;
    }

    .default-logo {
      display: none;
    }

    .alternate-logo {
      display: block;
    }

    .main-nav {
      padding-top: 20px;
      padding-bottom: 20px;

      .logo {
        min-width: 115px;

        svg {
          width: 90%;
          max-width: 115px;
        }

        @media (max-width: 375px) {
          width: auto;
          min-width: 95px;

          svg {
            max-width: 95px;
          }
        }
      }

      .nav-links a {
        color: $colorAccent1;

        &:before {
          background: $colorAccent1;
        }
      }

      .lang {
        color: $colorAccent1;
      }

      .mobile-menu-trigger {
        fill: $colorAccent1;
      }

      .site-btn {
        background: $colorAccent1;
        border-color: $colorAccent1;
        color: #fff;

        @media (hover: hover) {
          &:hover {
            background: #fff;
            color: $colorAccent1;
          }
        }
      }

      .site-btn.is-outlined {
        color: $colorAccent1;
        border-color: $colorAccent1;
        background: transparent;

        @media (hover: hover) {
          &:hover {
            border-color: $colorAccent2;
            color: $colorAccent2;
          }
        }
      }
    }
  }

.page-header.force-alt {
  top: 60px;
}

html[data-direction="down"] body.hide-nav .page-header {
  transform: translate3d(0, -150%, 0);
  transition: none;
}


.subnav {
  position: absolute;
  top: 148px;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  background: #fff;
  border-top: 1px solid rgba(#00ad52, 0.28);
  padding-top: 45px;
  padding-bottom: 45px;
  transition: opacity 0.3s ease-in-out, z-index 0.3s ease-in-out;

  &.is-active {
    z-index: 99;
    opacity: 1;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: calc(100% / 3);
      padding-top: 10px;
      padding-bottom: 10px;

      a {
        color: #007934;
        font-family: $fontText;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;

        &:hover,
        &.is-active {
          color: #f15f48;
        }
      }
    }
  }
}