.site-form {
  position: relative;
  width: 100%;
  padding-top: 2vw;

  .input-row {
    position: relative;
    width: 100%;

    input {
      width: 100%;
      border-radius: 45px;
      border: none;
      padding: 13px 25px;
      padding-top: 18px;

      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      color: $colorAccent2;

      @media (max-width: 599px) {
        padding: 10px 20px;
        padding-top: 15px;
      }

      &::placeholder {
        font-family: $fontTitle;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 1;
        text-transform: uppercase;
        color: $colorAccent2;
      }
    }

    &.alternate {
      input {
        border: 1px solid $colorAccent1;
        color: $colorAccent1;

        &::placeholder {
          color: $colorAccent1;
        }
      }
    }
  }

  .custom-select {
    display: inline-block;
    padding: 18px 30px 15px 30px;
    border-radius: 50px;
    border: none;
    min-width: 345px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: $colorAccent2;
    font-family: $fontTitle;
    text-align: left;

    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;

    background: url('/img/site/down.svg') right +10px center no-repeat;
    background-size: 30px;
    background-color: #fff;
  }

  .floating-submit {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 3px;
    z-index: 1;
    background: none;
    border: none;

    svg {
      width: 25px;
    }

    @media (max-width: 599px) {
      svg {
        width: 18px;
      }
    }
  }
}


.active-campaing-form {
  max-width: 80%;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 100%;
  }


  label {
    display: block;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 16px;
    color: $colorAccent2;
    text-align: left;

    @media (max-width: 599px) {
      font-size: 14px;
    }
  }

  .flex-it {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .input-row .form-field {
    width: 100%;
    background: #F0F8E9;
    color: #007934;
    border: none;
    font-size: 12px;
    font-family: $fontText;
    min-height: 45px;
    font-style: normal;
    font-weight: normal;
    border-radius: 50px;
    margin-bottom: 10px;
    text-transform: none;

    @media (max-width: 599px) {
      margin-bottom: 5px;
    }

    &::placeholder {
      color: #007934;
      font-size: 12px;
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      text-transform: none;
    }
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    padding: 17px 30px 14px 30px;
    border-radius: 50px;
    border: none;
    min-width: 345px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    background: #F0F8E9;
    color: #007934;
    font-family: $fontTitle;
    text-align: left;

    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;

    background: url('/img/site/down.svg') right +10px center no-repeat;
    background-size: 30px;
    background-color: #F0F8E9;
  }

  .submit {
    margin-top: 25px;
  }

  .form-submit {
    display: inline-block;
    cursor: pointer;
  }

  .full {
    width: 100%;
  }

  .half {
    width: calc(99% / 2);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .form-message {
    text-align: center;
    padding: 25px;
    color: $colorAccent1;
    font-size: 22px;
  }

  .only-success {
    display: none;
  }

  .hide-on-success {

  }

  &.is-success {
    .only-success {
      display: block;
    }

    .hide-on-success {
      display: none;
    }
  }
}

[data-select-ghost] {
  position: absolute;
  top: -5555px;
  left: -5555px;
}
