/**
 * app.css
 *
 * The entry point for the css.
 *
 */
@import './variables.pcss';

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
 /* @import "tailwindcss/base"; */
@import './components/normalize.pcss';

/**
 * This injects any component classes registered by plugins.
 *
 */
/* @import 'tailwindcss/components'; */

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */
@import './components/global.pcss';
@import './components/typography.pcss';
@import './components/btns.pcss';
@import './components/main-nav.pcss';
@import './components/mobile-nav.pcss';
@import './components/form.pcss';
@import './components/plateforme.pcss';

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */
/* @import 'tailwindcss/utilities'; */

/**
 * Include styles for individual pages
 *
 */
@import './pages/site.pcss';
@import './pages/votez.pcss';

/**
 * Include vendor css.
 *
 */
 @import 'vendor.pcss';
