/*! Site Buttons
---------------------------------------------- */
.site-btn-wrap {
  padding-top: 25px;
}
.site-btn {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 50px;
  border: 1px solid $colorAccent1;
  text-decoration: none;
  background: $colorAccent1;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  font-family: $fontTitle;
  text-align: center;
  text-transform: uppercase;

  transition: all $t2 $bounce 0s;

  .fix-type-height {
    position: relative;
    top: 1px;
  }

  svg {
    display: inline-block;
    margin-left: 10px;
    fill: #fff;
    transition: all 0.6s ease;
  }

  @media (max-width: 599px) {
    padding: 10px 21px;
  }

  @media (hover: hover) {
    &:hover {
      background: #fff;
      color: $colorAccent1;

      svg {
        fill: $colorAccent1;
      }
    }
  }

  &.is-white {
    background: #fff;
    color: $colorAccent2;
    border-color: #fff;

    @media (hover: hover) {
      &:hover {
        background: $colorAccent2;
        border-color: #fff;
        color: #fff;
      }
    }
  }

  &.is-long {
    position: relative;

    display: inline-block;
    padding: 15px 30px;
    border-radius: 50px;
    border: none;
    min-width: 325px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: $colorAccent2;
    font-family: $fontTitle;
    text-align: left;
    text-transform: uppercase;
    background-color: #fff;

    .fix-type-height {
      position: relative;
      top: 2px;
    }

    svg {
      margin: 0;
      padding: 0;

      fill: $colorAccent2;

      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }

    @media (hover: hover) {
      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
  }

  &.is-green {
    background: $colorAccent1;
    color: #fff;
    border-color: $colorAccent1;

    @media (hover: hover) {
      &:hover {
        background: $colorAccent5;
        border-color: $colorAccent5;
        color: #fff;
      }
    }
  }

  &.is-red {
    background: $colorAccent2;
    color: #fff;
    border-color: $colorAccent2;

    span {
      color: #fff;
    }

    @media (hover: hover) {
      &:hover {
        background: $colorAccent5;
        border-color: $colorAccent5;
        color: #fff;
      }
    }
  }

  &.is-alternate {
    background: $colorAccent5;
    color: #fff;
    border-color: $colorAccent5;

    @media (hover: hover) {
      &:hover {
        background: $colorAccent2;
        border-color: $colorAccent2;
        color: #fff;
      }
    }
  }

  &.is-outlined {
    background: transparent;
    color: #fff;
    border-color: #fff;

    @media (hover: hover) {
      &:hover {
        background: $colorAccent2;
        border-color: $colorAccent2;
        color: #fff;
      }
    }
  }
}

.cta-wrap {
  padding-top: 25px;
  text-align: center;
}

.pagination-wrap {
  padding-top: 0;
  padding-bottom: 4vw;

  a {
    margin: 10px 15px;
  }
}


.site-btn.close {
  position: absolute;
  bottom: 0;
  right: 0;

  display: inline-flex;
  align-items: center;
  padding: 10px 25px;
  color: #fff;

  &:hover {
    color: $colorAccent1;

    svg {
      fill: $colorAccent1;
    }
  }
}

.site-btn.form-checkbox {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 50px;
  border: 1px solid $colorAccent1;
  text-decoration: none;
  background: $colorAccent1;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  font-family: $fontTitle;
  text-align: center;
  text-transform: uppercase;

  transition: all $t2 $bounce 0s;

  .fix-type-height {
    position: relative;
    top: 1px;
  }

  svg {
    display: inline-block;
    margin-left: 10px;
    fill: #fff;
    transition: all 0.6s ease;
  }

  @media (max-width: 599px) {
    padding: 10px 21px;
  }

  @media (hover: hover) {
    &:hover {
      background: #fff;
      color: $colorAccent1;

      svg {
        fill: $colorAccent1;
      }
    }
  }

  &.is-long {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 50px;
    border: none;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #007934;
    font-family: $fontTitle;
    text-align: left;
    text-transform: uppercase;
    background-color: $colorAccent3;

    .fix-type-height {
      position: relative;
      top: 2px;
    }

    svg {
      margin: 0;
      padding: 0;

      stroke: #007934;
      fill: none;

      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }

    @media (hover: hover) {
      &:hover {
        svg {
          stroke: $colorAccent1;
          fill: none;
        }
      }
    }

    &.is-active {
      background: $colorAccent2;
      color: #fff;

      svg {
        margin: 0;
        padding: 0;

        stroke: #fff;
        fill: none;
      }
    }
  }
}
