.page-votez {
  .small-hero .site-form {
    max-width: 475px;
    margin: 0 auto;
  }

  .small-hero.votez {
    text-align: left;
    padding-left: 28vw;

    @media (max-width: 599px) {
      padding-left: 25px;
    }

    .big-title {
      span {
        display: block;
        text-align: left;

        font-size: 3.375vw;
        line-height: 1;
        color: $colorAccent1;

        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
      strong {
        display: block;
        text-align: left;

        font-size: 5.95vw;
        line-height: 1;

        @media (max-width: 767px) {
          font-size: 36px;
        }
      }
    }
  }

  .active-campaing-form {
    max-width: 100%;
  }

  .bloc-faq {
    .listing-headings {
      margin-bottom: 20px;
    }
    padding-bottom: 75px;
  }

  .bloc-votez {
    .listing-headings {
      margin-bottom: 20px;
    }
    padding-bottom: 75px;
  }

  .main-item-content {
    padding-top: 25px;
  }

  .plateforme .plateforme-item {
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .plateforme .reading-text.intro-reading-text {
    padding-bottom: 35px;
  }
}
