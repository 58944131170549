/**
 * components/typography.css
 *
 * Typography rules.
 *
 */

html {
  font-family: $fontText;
  font-weight: 500;
}

body {
  font-family: $fontText;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-family: $fontText;
  color: inherit;
  text-decoration: none;
  color: $colorAccent2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $fontTitle;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: bold;
}

p {
  font-family: $fontText;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-style: normal;
  font-weight: 500;
}

.big-title {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 1.2;
  text-transform: uppercase;

  @media (max-width: 1100px) {
    font-size: 40px;
  }

  @media (max-width: 890px) {
    font-size: 32px;
  }

  @media (max-width: 599px) {
    font-size: 28px;
  }
}

.medium-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 1.4;
  text-transform: uppercase;

  @media (max-width: 1100px) {
    font-size: 24px;
  }
}

.regular-title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.4;
}

.small-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.4;
}

.big-text {
  font-style: normal;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;

  @media (max-width: 1100px) {
    font-size: 16px;
  }
}

.regular-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;

  @media (max-width: 1100px) {
    font-size: 16px;
  }
}

.small-text {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;

  @media (max-width: 599px) {
    font-size: 14px;
  }
}

.smaller-text {
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
}
