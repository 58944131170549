/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

/*! Home Hero
---------------------------------------------- */
.home-hero {
  position: relative;
  padding-top: 17vw;
  padding-bottom: 2.5vw;
  text-align: left;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(#000, 0.25);
  }

  /* background: url('/img/site/projet-mtl-valerie-plante-v1-desktop.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: #1e685d; */

  @media (max-width: 767px) {
    /* background-image: url('/img/site/projet-mtl-valerie-plante-v1-mobile.jpg'); */
    padding-top: 80vw;
    padding-bottom: 35px;
    margin-bottom: 25px;

    /* background-size: 120% auto;
    background-position: center top; */
  }

  /* &.v2 {
    background-image: url('/img/site/projet-mtl-valerie-plante-v2-desktop.jpg');
    background-color: #10542f;

    @media (max-width: 767px) {
      background-image: url('/img/site/projet-mtl-valerie-plante-v2-mobile.jpg');
    }
  }

  &.v3 {
    background-image: url('/img/site/projet-mtl-valerie-plante-v3-desktop.jpg');
    background-color: #9a1c1f;

    @media (max-width: 767px) {
      background-image: url('/img/site/projet-mtl-valerie-plante-v3-mobile.jpg');
    }
  } */

  &.valerie-plante {
    background-image: url('/img/site/Image_cheffe_1920x1000.jpg');
    background-size: auto 100%;
    background-attachment: fixed;
    background-position: right top;
    background-color: #fff;

    @media (max-width: 767px) {
      background-image: url('/img/site/Image_cheffe_600x525.jpg');
    }

    &.fixed {
      background-attachment: fixed;
      background-position: right top;
    }

    @media (max-width: 767px) {
      background-size: 100% auto;
      background-attachment: scroll;
      background-position: right top;
      background-color: #fff;
      padding-top: 60vw;
      margin-bottom: 0;

      &.fixed {
        background-attachment: scroll;
        background-position: right top;
      }
    }

    .col-content {
      max-width: 45%;
      padding: 20px;
      background: rgba(#fff, 0.1275);

      @media (max-width: 767px) {
        max-width: 100%;
        padding: 0;
        padding-top: 35vw;
        background: none;
      }

      .big-title,
      .medium-text {
        color: $colorAccent1;
      }

      .medium-title {
        color: $colorAccent1;
      }

      .regular-text {
        color: $colorAccent1;

        p {
          color: $colorAccent1;
        }
      }
    }

    .contact-infos {
      color: $colorAccent1;
      margin-bottom: 25px;
      margin-top: 45px;

      svg {
        fill: $colorAccent1;
        width: 30px;
      }

      span {
        font-size: 13px;
        color: $colorAccent1;
      }

      .cols {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        flex-wrap: wrap;

        .col {
          position: relative;
          width: 100%;

          .new-window-notice {
            color: $colorAccent1;
          }

          a {
            position: relative;
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .home-video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .col-content {
    position: relative;
    z-index: 2;
    text-align: left;

    @media (max-width: 767px) {
      padding-top: 35vw;
    }

    .big-title,
    .medium-text {
      color: #fff;
      margin-bottom: 10px;
    }

    .medium-text {
      padding-top: 0;
      margin-bottom: 45px;
    }

    .medium-title {
      color: #fff;
      margin-top: 75px;
      margin-bottom: 25px;
    }

    .regular-text {
      color: #fff;
      font-weight: 300;
      margin-top: 0;
      line-height: 1.6;

      p {
        color: #fff;
        font-weight: 300;
        margin-top: 0;
        line-height: 1.6;
      }
    }
  }

  .big-title,
  .big-text {
    color: #fff;
    margin-bottom: 25px;
  }

  .big-text {
    padding-top: 15px;
  }

  .video-cta {
    padding-top: 10vw;

    a {
      display: block;
      display: flex;
      align-items: center;
    }

    svg {
      margin: 0;
      margin-right: 15px;
      padding: 0;
    }

    span {
      display: inline-block;
      max-width: 215px;
      color: #fff;
    }
  }

  .slogan {
    width: 145px;
    position: absolute;
    right: 20px;
    bottom: 40px;

    @media (max-width: 375px) {
      bottom: auto;
      top: 325px;
    }
  }
}

/*! Small Hero
---------------------------------------------- */
.small-hero {
  position: relative;
  padding-top: 10vw;
  padding-bottom: 10vw;
  text-align: center;

  background: url('/img/site/banniere-equipe2.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: $colorAccent1;

  margin-top: 100px;

  &.medium-hero {
    margin-top: 0;
    padding-top: 15vw;
  }

  @media (max-width: 890px) {
    padding-top: 100px;
    padding-bottom: 80px;

    background-image: url('/img/site/banniere-equipe-mobile2.jpg');

    background-position: left center;

    &.medium-hero {
      padding-top: 30vw;
    }
  }

  &.video-hero {
    margin-top: 0;
    padding-top: 23vw;
    padding-bottom: 19vw;

    @media (max-width: 767px) {
      padding-top: 40vw;
      padding-bottom: 30vw;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(#000, 0.25);
    }
  }

  &.nouvelles, &.vert {
    background-image: url('/img/site/banniere-nouvelles.jpg');

    @media (max-width: 890px) {
      background-image: url('/img/site/banniere-nouvelles-mobile.jpg');
    }
  }

  &.evenements, &.rouge {
    background-image: url('/img/site/banniere-evenements.jpg');

    @media (max-width: 890px) {
      background-image: url('/img/site/banniere-evenements-mobile.jpg');
    }
  }

  &.jaune {
    background-image: url('/img/site/banniere-page-votez-plain.png');
    background-color: #fff;
  }

  &.votez {
    background-image: url('/img/site/banniere-page-votez.png');
    background-color: #fff;

    @media (max-width: 599px) {
      background-position: right -100px bottom;
      background-size: auto 100%;
    }
  }

  .home-video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  & > *:not(.home-video) {
    position: relative;
    z-index: 2;
  }

  .big-title,
  .big-text {
    color: #fff;

    @media (max-width: 767px) {
      margin-bottom: 25px;

      br {
        display: none;
      }
    }
  }

  .small-text {
    color: #fff;
    font-weight: 300;
    padding-top: 15px;
  }

  .big-text {
    padding-top: 15px;
    padding-bottom: 25px;

    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}

/*! Details Hero
---------------------------------------------- */
.details-hero {
  position: relative;
  overflow: hidden;

  margin-top: 115px;
  padding-top: 0;
  padding-bottom: 0;

  background: $colorAccent1;

  @media (max-width: 890px) {
    margin-top: 75px;

    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 599px) {
    margin-top: 75px;
  }

  .credits {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
    padding: 13px 25px;
    background: rgba(#000, 0.5777);
    color: #fff;
    font-size: 12px;

    @media (max-width: 890px) {
      bottom: auto;
      top: 13px;
    }

    @media (max-width: 399px) {
      bottom: auto;
      top: 6px;
    }
  }

  &.theme-vert {
    background-color: #00ad52;

    .col-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      background: #68c187;
    }
  }

  &.theme-rouge {
    background-color: #f15f48;

    .col-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      background: #f69382;
    }
  }

  &.theme-bleu {
    background-color: #00b1a3;

    .col-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      background: #75cbc4;
    }
  }

  &.theme-white,
  &.theme-white.fixed-hero {
    background-color: #fff;

    .col-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      background: #fff;
    }

    @media (max-width: 890px) {
      &,
      &.fixed {
        .col.right {
          position: relative;
          left: 0;
          img {
            position: relative;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 110%;
            margin-left: -20px;
          }
        }
      }
    }

    .description, .description.small-text, .description.small-text p {
      color: #43544B;
      line-height: 1.6;
    }

    .big-text, .medium-text, .big-title {
      color: $colorAccent1;
    }

    .medium-text {
      margin-bottom: 45px;
      color: #43544B;
    }

    .medium-title {
      color: $colorAccent1;
      margin-top: 75px;
    }

    .site-btn {
      margin-bottom: 10px;
    }

    .contact-infos {
      margin-top: 45px;
      margin-bottom: 0;

      color: #43544B;

      span {
        color: #43544B;
      }

      svg {
        fill: $colorAccent1;
      }
    }
  }

  &.page-valerie {
    overflow: visible;
    width: 100%;
    display: flex;
    align-items: stretch;

    @media (max-width: 890px) {
      flex-direction: column-reverse;
    }

    .col.col-img.right {
      position: relative;
      top: initial;
      right: initial;
      bottom: initial;
      left: initial;

      .img-container {
        width: 100%;
        height: 100svh;
        display: flex;
        margin-top: -175px;
        position: sticky;
        top: 0;

        @media (max-width: 890px) {
          position: relative;
          margin-top: 0;
        }

        img {
          position: relative;
          top: initial;
          right: initial;
          bottom: initial;
          left: initial;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;

          @media (max-width: 890px) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .hero-img {
    display: block;

    position: absolute;
    top: 0;
    right: 0;

    width: 50%;
    height: auto;
    min-height: 100%;

    object-fit: cover;
    opacity: 1;

    @media (max-width: 890px) {
      position: relative;
      width: 120%;
      margin-left: -20px;
    }
  }

  .hero-container {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    width: 100%;

    padding-top: 0;
    padding-bottom: 0;

    .col {
      width: 50%;

      @media (max-width: 890px) {
        width: 100%;
      }
    }

    .col-content {
      padding-top: 8vw;
      padding-bottom: 45px;
      padding-right: 6vw;

      @media (max-width: 890px) {
        padding-right: 0;
      }

      .medium-title,
      .big-title {
        color: #fff;
      }

      .date,
      .date.small-text {
        display: block;
        padding-top: 154px;
        color: #fff;

        @media (max-width: 890px) {
          padding-top: 65px;
        }
      }

      .small-text {
        color: #fff;
      }

      .description {
        padding: 35px 0;
        margin-bottom: 45px;

        font-style: normal;
        font-weight: normal;
        line-height: 2;

        &.less-b {
          margin-bottom: 0;
        }

        @media (max-width: 599px) {
          margin-top: 0;
          padding-top: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .site-btn {
        margin-bottom: 45px;
      }

      .contact-infos {
        color: #fff;
        margin-bottom: 25px;
        margin-top: -20px;

        svg {
          fill: #fff;
          width: 30px;
        }

        span {
          font-size: 13px;
          color: #fff;
        }

        .cols {
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;

          flex-wrap: wrap;

          .col {
            position: relative;
            width: calc(100% / 2);

            &.add-space {
              margin-top: 2px;
              margin-bottom: 2px;
            }

            @media (max-width: 1599px) {
              width: 100%;
            }

            .new-window-notice {
              color: $colorAccent1;
            }

            a {
              position: relative;
              display: flex;
              align-items: center;

              svg {
                margin-right: 10px;
              }
            }
          }
        }
      }

      .arrondissements-infos {
        margin-top: 25px;
        margin-bottom: -45px;

        @media (max-width: 599px) {
          margin-bottom: 0;
        }
      }

      .infos {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        padding-top: 15px;
        padding-bottom: 3vw;

        @media (max-width: 890px) {
          padding-top: 15px;
          padding-bottom: 35px;
        }

        @media (max-width: 599px) {
          padding-top: 0;
        }

        .date,
        .small-text,
        .date.small-text {
          padding: 0;
          color: #fff;
        }

        .adresse {
          display: flex;
          align-items: center;

          svg {
            fill: #fff;
            margin-left: 35px;
            margin-right: 10px;
          }
        }
      }

      .infos-evenements {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .adresse {
          align-items: flex-start;
          margin-top: 15px;
          margin-bottom: 5px;
          line-height: 1.4;

          .new-window-notice {
            color: $colorAccent1;
          }

          &:last-child {
            margin-bottom: -15px;
          }

          svg {
            position: relative;
            top: -5px;
            margin-left: 0;
          }
        }
      }

      .arrondissement {
        padding: 0;
        margin: 0;
        padding-top: 6vw;
        color: #fff;
        line-height: 1;

        &.small-padding {
          padding-top: 0;
        }

        @media (max-width: 890px) {
          padding-top: 45px;
        }
      }
    }

    .col-img {
      position: relative;
      height: auto;
    }

    .col-iframe {
      position: relative;
      borde-radius: 5px;
      padding-bottom: 6vw;

      .inner {
        position: relative;
        top: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 6vw;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 35px;
        padding-bottom: 30px;

        .title {
          color: $colorAccent2;
          padding-left: 15px;
          font-size: 24px;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 0;
          margin-bottom: -5px;
          padding-bottom: 30px;
        }

        iframe {
          height: 600px;
          min-height: 600px;
        }
      }
    }
  }

  .back-btn {
    position: absolute;
    top: 4vw;
    left: 4vw;
    color: #fff;
    font-size: 12px;

    z-index: 10;

    display: flex;
    align-items: center;

    transition: color $t2 $bounce 0s;

    @media (max-width: 890px) {
      top: 45px;
      left: 20px;
      color: #fff;

      background: rgba(0, 0, 0, 0.223);
      padding: 5px;
      border-radius: 50px;
    }

    svg {
      fill: #fff;
      margin-right: 10px;

      transition: fill $t2 $bounce 0s;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
  }

  &.hero-equipe {
    .hero-img {
      margin-top: 0;
      min-height: 110vh;
      max-height: 110vh;

      @media (max-width: 890px) {
        display: block;
        position: relative;
        margin-top: 0;
        min-height: inherit;
        max-height: inherit;
        width: 120%;
        margin-left: -20px;
        height: auto;
      }
    }

    .hero-container {

    }
  }
}

/*! Listing
---------------------------------------------- */
.articles-listing {
  position: relative;
  width: 100%;

  padding-top: 4vw;
  padding-bottom: 4vw;

  &.keep-border {
    border-bottom: 4px solid rgba($dark, 0.123);
    margin-bottom: 45px;
    background: $light;
  }

  @media (max-width: 890px) {
    padding-top: 45px;
    padding-bottom: 10px;

    &.keep-border {
      padding-top: 0;
    }
  }

  .listing-container {
    position: relative;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    .article-item {
      position: relative;
      width: calc(96% / 3);
      margin-right: 1%;
      background: $light;
      margin-bottom: 2%;
      border-radius: 4px;
      overflow: hidden;

      transition: transform $t2 $bounce 0s;

      @media (hover: hover) {
        &:not(.no-hover) {
          &:hover {
            transform: scale(0.9875);

            .img-wrap {
              img {
                transform: scale(1.27524);
              }
            }
          }
        }
      }

      @media (max-width: 890px) {
        width: calc(98% / 2);
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 25px;
      }

      .article-link {
        display: block;
        width: 100%;
        height: 100%;
      }

      .img-wrap {
        position: relative;
        display: block;
        overflow: hidden;

        border-radius: 4px;

        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba($colorAccent1, 0.1);
          z-index: 1;
        }

        .tag {
          display: inline-block;
          position: absolute;
          top: 15px;
          left: 15px;
          z-index: 2;
          background: #fff;
          padding: 8px 15px;
          border-radius: 75px;
          font-weight: 500;
          color: $colorAccent2;
        }

        .date,
        .date.small-text {
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: $colorAccent2;
          padding: 20px 25px;
          padding-right: 45px;
          border-top-right-radius: 75px;
          font-weight: 500;
          color: #fff;
        }

        img {
          display: block;
          width: 100%;

          &.fix-en {
            max-height: 220px;
            object-fit: cover;
          }

          transform: scale(1);
          transition: transform 8s ease 0s;
        }
      }

      .content {
        position: relative;
        padding: 30px;

        .big-text,
        .regular-text,
        .small-text {
          font-family: $fontText;
          color: $colorAccent1;
        }

        .big-text {
          min-height: 90px;
        }

        .small-text {
          padding-bottom: 10px;
        }

        .date,
        .date.small-text {
          position: relative;
          top: 25%;

          padding-top: 4vw;
          padding-bottom: 0;
          padding-right: 45px;
          color: $colorAccent5;

          display: flex;
          align-items: center;
          justify-content: flex-start;

          svg {
            margin-right: 10px;
            min-width: 24px;
            max-width: 24px;
          }
        }
      }

      svg {
        fill: $colorAccent5;
      }

      .icon {
        position: absolute;
        bottom: 30px;
        right: 25px;
        width: 34px;
        transform: translate3d(0, 0, 0);
      }
    }

    .article-item.item-event {
      background: $alternateLight;

      .img-wrap {
        &:before {
          background-color: rgba($colorAccent2, 0.1);
        }
      }

      .content {
        .regular-text,
        .big-text,
        .small-text {
          color: $colorAccent2;
        }

        .date {
          color: $colorAccent2;
        }

        svg {
          fill: $colorAccent2;
        }
      }
    }

    .article-item.item-equipe {
      background: transparent;

      .img-wrap {
        &:before {
          display: none;
        }
      }

      .content {
        background: transparent;
        padding: 15px 0;

        .regular-text,
        .big-text,
        .small-text {
          color: $colorAccent1;
          padding: 0;
        }

        .big-text {
          min-height: inherit;
        }

        .date {
          color: $colorAccent2;
          padding: 0;
        }

        svg {
          fill: $colorAccent1;
        }
      }
    }
  }
}

/*! Details / Content block
---------------------------------------------- */
.block-details-content {
  @media (max-width: 767px) {
    border-bottom: 4px solid rgba($dark, 0.123);
  }

  &.keep-border {
    border-bottom: 4px solid rgba($dark, 0.123);
  }

  .details-content-container {
    position: relative;
    overflow: hidden;
    max-width: 1100px;
    border-radius: 8px;
    padding: 4vw 0;
    padding-bottom: 0;

    .reading-text {
      position: relative;
      z-index: 2;
      max-width: 800px;
      margin: 0 auto;

      @media (max-width: 890px) {
        padding: 20px 0;
      }

      &.padding {
        &:before {
          content: '';
          position: absolute;
          top: -5vw;
          right: -5vw;
          bottom: -5vw;
          left: -5vw;
          z-index: -1;
        }
      }

      br {
        display: none;
      }
    }

    .regular-title, h2, h3, h4, h5, h6 {
      margin-bottom: 10px;
      color: $colorAccent1;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 1.6;
      margin-top: 40px;

      br {
        display: none;
      }

      @media (max-width: 890px) {
        font-size: 22px;
        margin-top: 20px;
      }
    }

    h4 {
      color: $colorAccent2;
    }

    &, .regular-text, p, ul, ul li {
      color: #43544B;
      font-weight: 400;
      margin-bottom: 15px;
      line-height: 1.6;
      font-size: 18px;

      br {
        display: none;
      }

      @media (max-width: 1100px) {
        font-size: 16px;
      }

      a {
        color: $colorAccent2;
      }
    }

    ul li {
      margin: 0;
    }

    li {
      margin-bottom: 10px;
    }

    figure {
      margin: 0;
      padding: 0;
      padding-bottom: 35px;

      font-size: 14px;

      figcaption {
        padding: 0 5px;
      }
    }

    img.full,
    figure img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px;
    }

    .iframe-fix {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 25px 0;

      iframe {
        position: relative;
        width: 100% !important;
        height: auto !important;
        min-height: 32vw !important;

        @media (max-width: 700px) {
          min-height: 50vw !important;
        }
      }
    }
  }
}

/*! Pre footer
---------------------------------------------- */
.pre-footer {
  position: relative;
  width: 100%;
  overflow: hidden;

  background-color: $colorAccent1;
  color: #fff;

  .pre-footer-container {
    position: relative;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    flex-wrap: wrap;

    .col {
      width: calc(100% / 3);
      background-color: $colorAccent1;

      padding-top: 6vw;

      @media (max-width: 890px) {
        width: 100%;
      }
    }

    .col-links {
      padding-bottom: 2vw;
      .medium-title {
        margin-bottom: 30px;
      }

      .site-btn {
        margin-top: 25px;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          padding-bottom: 20px;

          &:last-child {
            padding-bottom: 0;
          }

          a {
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .link {
        color: #fff;
      }
    }

    .col-newsletter {
      padding-right: 0;
      background-color: $colorAccent1;

      @media (max-width: 890px) {
        padding-right: 0;
        padding-bottom: 6vw;
      }

      .icon {
        width: 55px;
        margin-top: 45px;
      }

      .regular-text,
      .medium-title,
      .big-text {
        color: #fff;
        font-weight: 300;
        font-size: 16px;
      }

      .medium-title {
        margin-bottom: 30px;
      }

      .site-btn {
        margin-top: 25px;
      }
    }

    .col-contact {
      position: relative;
      padding-left: 0;
      padding-right: 6vw;
      color: #fff;

      .regular-text,
      .medium-title,
      .big-text {
        color: #fff;
      }

      @media (max-width: 890px) {
        padding-left: 0;
      }

      .content {
        position: relative;
        z-index: 2;

        @media (max-width: 1175px) {
          display: flex;
          flex-direction: column;
        }

        span {
          display: inline-flex;
          align-items: center;
          color: $colorAccent1;

          @media (max-width: 767px) {
            margin-top: 10px;
          }

          a {
            color: #fff;
            transition: color $t2 $bounce 0s;

            @media (hover: hover) {
              &:hover {
                color: #fff;
              }
            }

            @media (max-width: 767px) {
              font-size: 13px;
            }
          }

          svg {
            margin-right: 10px;
          }
        }
      }

      .contact-infos {
        padding: 0 0;
        padding-bottom: 0;
        font-weight: 300;
        font-size: 16px;

        span {
          display: inline-block;
          padding-top: 20px;
        }

        span:first-child {
          padding-right: 45px;
          min-width: 230px;

          @media (max-width: 1175px) {
            margin-bottom: 5px;
          }
        }

        @media (max-width: 1175px) {
          display: flex;
          flex-direction: column;
          padding-bottom: 0;

          span {
            margin-bottom: 15px;
          }
        }
      }

      .adresse {
        padding: 0;
        padding-top: 1.5em;
        display: flex;
        align-items: center;

        font-weight: 300;
        font-size: 16px;

        svg {
          margin-right: 10px;
        }

        @media (max-width: 1175px) {
          width: 100%;
          padding-top: 0;
          margin-top: -5px;
        }
      }

      .reseaux {
        padding-top: 20px;
        padding-bottom: 2vw;

        svg {
          fill: #fff;

          transition: fill $t2 $bounce 0s;

          @media (hover: hover) {
            &:hover {
              fill: $colorAccent2;
            }
          }
        }

        a {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }
}

/*! Site Footer
---------------------------------------------- */
.site-footer {
  position: relative;
  width: 100%;
  background: #F0F8E9;

  .footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 5px;
    padding-bottom: 5px;

    @media (max-width: 890px) {
      flex-wrap: wrap;
      text-align: center;
    }

    .smaller-text {
      margin: 0;
      padding: 0;
      line-height: 2;
      color: #007934;
      font-weight: 300;

      &.text-right {
        text-align: right;

        a {
          color: #007934;
          text-decoration: underline;
          padding-left: 20px;
        }

        @media (max-width: 890px) {
          text-align: center;
          display: inline-block;
          padding-top: 25px;
        }
      }

      span {
        color: #007934;
      }

      @media (max-width: 890px) {
        width: 100%;
      }
    }
  }
}

.articles-listing.equipe-listing {
  .listing-headings {
    margin: 0;
    padding: 0;
    padding-bottom: 0;
    margin-top: 45px;
    margin-bottom: 25px;

    .small-text {
      color: #43544B;
    }

    p, ul, li {
      color: #43544B;
    }
  }

  .article-item {
    position: relative;
    width: calc(96% / 4);
    background: $light;
    margin-bottom: 2%;
    border-radius: 4px;
    overflow: hidden;

    transition: transform $t2 $bounce 0s;

    @media (hover: hover) {
      &:hover {
        transform: scale(0.9875);

        .img-wrap {
          img {
            transform: scale(1.27524);
          }
        }
      }
    }

    @media (max-width: 890px) {
      width: calc(98% / 3);
    }

    @media (max-width: 767px) {
      width: calc(98% / 2);
    }

    @media (max-width: 374px) {
      width: 100%;
    }
  }
}

.articles-listing.arrondissements-listing {
  margin-bottom: 0;
}

/*! Infos Cols
---------------------------------------------- */
.infos-cols {
  padding-top: 2.5vw;

  .cols {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;

    .col {
      width: calc(100% / 4);

      @media (max-width: 890px) {
        width: calc(100% / 2);
        margin-bottom: 25px;
      }

      @media (max-width: 374px) {
        width: 100%;
      }
    }

    a {
      display: flex;
      align-items: center;
      line-height: 1;

      margin: 0;
      padding: 0;

      color: $colorAccent1;

      svg {
        margin-right: 15px;
      }
    }
  }
}

/*! Split
---------------------------------------------- */
.split {
  padding-top: 4vw;
  background: #fff;

  @media (max-width: 860px) {
    padding-top: 45px;
  }

  .cols {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .col {
      width: calc(100% / 2);

      @media (max-width: 890px) {
        width: 100%;
      }
    }

    .col-img {
      padding-right: 25px;

      @media (max-width: 890px) {
        padding: 0;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 890px) {
          max-height: 255px;
        }
      }
    }

    .col-content {
      padding-left: 25px;
      color: $colorAccent1;

      @media (max-width: 890px) {
        padding: 25px 0;
      }

      .small-title {
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
      }

      .regular-text {
        padding-top: 25px;
        padding-bottom: 45px;
        font-style: normal;
        font-weight: normal;
        line-height: 2;
      }
    }
  }
}

/*! Popups
---------------------------------------------- */
.popup-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba($colorAccent1, 0.80);

  z-index: 99;

  opacity: 0;
  z-index: -1;
  transition: opacity $t2 $bounce 0s, z-index $t2 $bounce 0s;

  &.is-active {
    opacity: 1;
    z-index: 9999999999;

    .popup-content {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);

      transition-delay: 0.32s;

      @media (max-width: 599px) {
        transform: translate(-50%, -50%) scale(0.95);
      }
    }
  }

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    background: #fff;

    width: 100%;
    max-width: 745px;
    height: auto;

    padding: 45px 45px;
    text-align: center;
    border-radius: 4px;

    opacity: 0;
    transition: opacity $t2 $bounce 0s, transform $t2 $bounce 0s;

    @media (max-width: 767px) {
      padding: 45px 15px;
    }

    .icon {
      width: 55px;

      @media (max-width: 599px) {
        display: none;
      }
    }

    .medium-title {
      color: $colorAccent1;
      padding: 20px 0;
      padding-bottom: 0;
      margin-bottom: 0;

      @media (max-width: 599px) {
        padding-top: 0;
        padding-bottom: 10px;
        margin-top: -5px;
      }
    }

    .ctas {
      padding-top: 5px;

      .site-btn {
        margin: 10px 10px;

        @media (max-width: 767px) {
          min-width: 70%;
        }
      }
    }

    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
  }
}

.equipe-home-listing {
  position: relative;
  width: 100%;

  padding-top: 4vw;
  padding-bottom: 0;

  color: $colorAccent1;

  @media (max-width: 890px) {
    padding-top: 45px;
    padding-bottom: 25px;
  }

  .listing-container {
    position: relative;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .listing-headings {
      width: 100%;
      margin-bottom: 4vw;
      color: $colorAccent1;

      .small-text {
        color: #43544B;
      }

      p, ul, li {
        color: #43544B;
      }

      &.for-evenements {
        margin-bottom: 2vw;
      }

      .small-title {
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        line-height: 1.4;
      }
    }

    .membres-grid {
      position: relative;
      width: 100%;
      overflow: hidden;

      .membres-row {
        width: auto;
        display: flex;
        margin-bottom: 2%;

        .article-link {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
        }

        .article-item {
          min-width: 235px;
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 2%;
        }
      }
    }
  }
}

/*! Home Blocks
---------------------------------------------- */
.equipe-home-grid {
  position: relative;
  width: 100%;

  padding-bottom: 4vw;
  color: $colorAccent1;

  @media (max-width: 890px) {
    padding-bottom: 45px;
  }

  .listing-container {
    position: relative;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .membres-grid {
      position: relative;
      width: 100%;
      overflow: hidden;

      .membres-row {
        width: auto;
        display: flex;
        margin-bottom: 1.5%;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }

        .article-item {
          min-width: 235px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 1.5%;

          @media (max-width: 767px) {
            min-width: 115px;
            margin-right: 15px;
          }

          .img-wrap {
            position: relative;
            border-radius: 4px;
            overflow: hidden;

            img {
              position: relative;
              border-radius: 4px;
              overflow: hidden;
              object-fit: cover;
              width: 100%;
            }
          }
        }
      }
    }

    .anim .upper-row {
      animation: infinityRight 300s linear infinite;
    }

    .anim .bottom-row {
      animation: infinityLeft 300s linear infinite;
    }
  }
}

@keyframes infinityRight {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes infinityLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


.listing-headings {
  position: relative;
  width: 100%;
  margin-bottom: 4vw;
  color: $colorAccent1;

  .small-text {
    color: #43544B;
  }

  p, ul, li {
    color: #43544B;
  }

  &.for-evenements {
    margin-bottom: 2vw;
  }

  .small-title {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
  }

  .small-text {
    line-height: 1.6;
    margin-bottom: 25px;
    font-weight: 400;
  }

  .site-btn {
    position: absolute;
    bottom: auto;
    top: 0;
    right: 0;
    transform: none;

    @media (max-width: 860px) {
      position: relative;
      bottom: 0;
      top: 0;
      transform: none;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &.center-btn {
    .site-btn {
      top: 0;
      transform: translate(0, -15%);

      @media (max-width: 860px) {
        top: auto;
      }
    }
  }
}

body #page-container main .mobile-scroller {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;

  @media (max-width: 860px) {
    width: 120%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-right: -20px;

    overflow-x: auto;
    overflow-y: hidden;

    .article-item {
      width: 400px;
      min-width: 400px;
      max-width: 400px;
      margin-right: 20px;
    }
  }

  @media (max-width: 599px) {
    .article-item {
      width: 280px;
      min-width: 280px;
      max-width: 280px;
    }
  }
}


.fixed-hero {
  padding-top: 0;
  position: relative;
  overflow: hidden;

  .col {
    width: 50%;

    @media (max-width: 890px) {
      width: 100%;
    }
  }

  .col-content {
    padding-top: 8vw;
    padding-bottom: 45px;
    padding-right: 6vw;
    padding-left: 7vw;

    min-height: 101vh;

    @media (max-width: 890px) {
      padding-right: 20px;
      padding-left: 20px;
    }

    .medium-title,
    .big-title {
      color: #fff;
    }

    .date,
    .date.small-text {
      display: block;
      padding-top: 154px;
      color: #fff;

      @media (max-width: 890px) {
        padding-top: 65px;
      }
    }

    .small-text {
      color: #fff;
    }

    .description {
      padding: 35px 0;
      margin-bottom: 45px;

      font-style: normal;
      font-weight: normal;
      line-height: 2;

      @media (max-width: 599px) {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .site-btn {
      margin-bottom: 45px;
    }

    .contact-infos {
      color: #fff;
      margin-bottom: 25px;
      margin-top: -20px;

      svg {
        fill: #fff;
        width: 30px;
      }

      span {
        font-size: 13px;
        color: #fff;
      }

      .cols {
        display: flex;
        align-items: center;
        justify-content: space-between;

        flex-wrap: wrap;

        width: 100%;

        .col {
          position: relative;
          width: calc(100% / 2);

          &.add-space {
            margin-top: 4px;
            margin-bottom: 4px;
          }

          @media (max-width: 1599px) {
            width: 100%;
          }

          .new-window-notice {
            color: $colorAccent1;
          }

          a {
            position: relative;
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .infos {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding-top: 15px;
      padding-bottom: 3vw;

      @media (max-width: 890px) {
        padding-top: 15px;
        padding-bottom: 35px;
      }

      @media (max-width: 599px) {
        padding-top: 0;
      }

      .date,
      .small-text,
      .date.small-text {
        padding: 0;
        color: #fff;
      }

      .adresse {
        display: flex;
        align-items: center;

        svg {
          fill: #fff;
          margin-left: 35px;
          margin-right: 10px;
        }
      }
    }

    .infos-evenements {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .adresse {
        align-items: flex-start;
        margin-top: 15px;
        margin-bottom: 5px;
        line-height: 1.4;

        .new-window-notice {
          color: $colorAccent1;
        }

        &:last-child {
          margin-bottom: -15px;
        }

        svg {
          position: relative;
          top: -5px;
          margin-left: 0;
        }
      }
    }

    .arrondissement {
      padding: 0;
      margin: 0;
      padding-top: 6vw;
      color: #fff;
      line-height: 1;

      &.small-padding {
        padding-top: 0;
      }

      @media (max-width: 890px) {
        padding-top: 45px;
      }
    }
  }

  .col.right {
    img {
      position: absolute;
      top: auto;
      bottom: -75px;
      right: 0;
      width: 100%;
    }
  }

  &.fixed {
    .right {
      img {
        position: fixed;
        top: 75px;
        right: 0;
        width: 50%;
      }
    }
  }

  @media (max-width: 890px) {
    &,
    &.fixed {
      .col.right {
        position: relative;
        left: 0;
        img {
          position: relative;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 110%;
          margin-left: -20px;
        }
      }
    }
  }
}

.articles-listing.for-arrondissements {
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0;
  margin-top: -25px;
}

.block-details-content.for-arrondissements {
  margin-top: -45px;
}

.block-details-content .details-content-container.for-arrondissements {
  padding-top: 0;
  max-width: 1240px;
  padding-bottom: 0;
}

.block-details-content .details-content-container .reading-text.for-arrondissements {
  padding-top: 0;
  max-width: 100%;
  padding-bottom: 0;
}

.block-details-content .details-content-container .reading-text.for-arrondissements {
  br {
    display: none;
  }

  h2, h3, h4, h5, h6 {
    padding-top: 0;
    padding-bottom: 5px;
    margin-bottom: 0;
  }

  h4 {
    color: $colorAccent2;
  }

  p {
    padding: 4px 0;
    margin: 0;
    padding-top: 10px;
  }

  ul {
    margin: 0;
    padding-top: 5px;
  }

  ul li {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  li p {
    margin: 0;
    padding: 0;
  }
}


.recherche {
  .max-container {
    max-width: 60vw;
    margin: 0 auto;
    padding: 0 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      color: #43544B;

      .right {
        width: 140px;
        min-width: 140px;
        border-radius: 10px;
        margin-left: 6vw;

        img {
          display: block;
          width: 100%;
          border-radius: 10px;
        }
      }

      .medium-title {
        color: $colorAccent1;
        font-size: 20px;
      }

      .small-title {
        color: $colorAccent2;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 10px;
      }

      .reading-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.55;
      }
    }
  }
}


.intro-text {
  padding-top: 3vw;
  padding: 3vw;
  .text {
    color: #43544B;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 39.6px */
    width: 100%;
    max-width: 840px;
    margin: 0 auto;

    @media (max-width: 767px) {
      font-size: 16px;
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
}

.thumbs-nav {
  position: relative;
  .thumbs-row {
    padding-bottom: 6vw;
  }
  .thumbs-title {
    color: var(--vert, #00AD52);
    padding-bottom: 3vw;

    @media (max-width: 767px) {
      font-size: 20px;
      padding-bottom: 25px;
    }
  }
  .thumbs-items {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      width: 120%;
      margin-right: -20px;
      margin-left: -20px;
      padding-left: 20px;
      padding-right: 40px;
    }
  }
  
  .thumbs-item {
    width: calc(98% / 2);
    padding-bottom: 40px;
    margin-right: 2%;

    @media (max-width: 767px) {
      min-width: 300px;
      margin-right: 20px;
    }

    &:nth-child(2n) {
      margin-right: 0;

      @media (max-width: 767px) {
        margin-right: 20px;
      }
    }

    &.has-3 {
      width: calc(96% / 3);
      
      margin-right: 2%;

      @media (max-width: 767px) {
        min-width: 300px;
        margin-right: 20px;
      }

      &:nth-child(2n) {
        margin-right: 2%;

        @media (max-width: 767px) {
          margin-right: 20px;
        }
      }

      &:nth-child(3n) {
        margin-right: 0;
        
        @media (max-width: 767px) {
          margin-right: 20px;
        }
      }
    }

    .img-wrap {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      
      img {
        display: block;
        width: 100%;
        border-radius: 5px;
        z-index: 2;
        transition: transform $t2 $bounce 0s;
      }

      position: relative;
      
      &:before {
        border-radius: 5px;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.6;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
        z-index: 3;
      }

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 4;

        color: var(--blanc, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 28px */

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        @media (max-width: 767px) {
          font-size: 16px;
        }

        svg {
          width: 28px;
          height: auto;
        }
      }
    }

    .text {
      color: #43544B;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */
      padding-top: 20px;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    &:hover {
      .img-wrap {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}


.green-big-title {
  color: var(--vert, #00AD52);
  padding-bottom: 45px;
  padding-top: 15px;

  @media (max-width: 767px) {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}